<template>
 <div id="ComparePlans">
<Navigation/>
  <div class="container-compare-plan-table">
   <div class="header-table-price">
    <b-container>
     <b-row>
      <b-col>
       <div class="text-title">
        <p>FLAT 10% FOR LIFE TIME USE PROMO</p>
        <h2>Compare Shared Hosting</h2>
       </div>
      </b-col>
     </b-row>
    </b-container>
   </div>
   <div class="body-table">
    <b-container>
     <b-row>
      <b-col>
       <table class="table">
        <div class="header"></div>
        <thead class="thead">
         <tr>
          <th>Sahara Basic</th>
          <th>Sahara Bronze</th>
          <th>Sahara Silver</th>
          <th>Sahara Gold</th>
         </tr>
        </thead>
        <tbody>
         <tr class="change-color">
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
         </tr>
         <tr>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
         </tr>
         <tr class="change-color">
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
         </tr>
         <tr>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
         </tr>
         <tr class="change-color">
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
         </tr>
         <tr>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
         </tr>
         <tr class="change-color">
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
         </tr>
         <tr>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
         </tr>
         <tr class="change-color">
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
         </tr>
         <tr>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
         </tr>
         <tr class="change-color">
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
         </tr>
         <tr>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
         </tr>
         <tr class="change-color">
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
         </tr>
         <tr>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
         </tr>
         <tr class="change-color">
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
         </tr>
         <tr>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
          <td>Disk Space 5GB</td>
         </tr>
        </tbody>
        <tfoot>
         <tr>
          <th><button>Order Now!</button></th>
          <th><button>Order Now!</button></th>
          <th><button>Order Now!</button></th>
          <th><button>Order Now!</button></th>
         </tr>
        </tfoot>
       </table>
      </b-col>
     </b-row>
    </b-container>
   </div>
  </div>
  <Partners />
  <Footer />
 </div>
</template>

<script>

import Partners from "../../../home/components/Partners.vue";
import Footer from "../../../home/components/Footer.vue";
import Navigation from "../../../home/components/Navigation.vue";
export default {
 name: "SharedHosting",
 components: {
    Partners,
    Footer,
    Navigation
},
};
</script>

<style lang="scss" scoped>
#ComparePlans {

 .container-compare-plan-table {

  .header-table-price {
   padding: 85px  0 0 0 ;
   background-color: #0e0b2b;
   .text-title {
    display: flex;
    flex-flow: column;
    margin: 45px 0 25px 0;

    p {
     font-size: 14px;
     font-family: "Montserrat", sans-serif !important;
     font-weight: 600;
     color: #ffbe3d;
     text-align: center;
     padding: 0;
     margin-bottom: 3px;
    }
    h2 {
     font-size: 36px;
     color: blanchedalmond;
     font-weight: 900;
     font-family: "Overpass", sans-serif;
     text-align: center;
     text-transform: uppercase;
    }
   }
  }
  .body-table {
   background-color: #0e0b2b;
   .table {
    border-radius: 6px;
    border: 1px solid #008aff;
    box-shadow: 0px 0px 5px #ccc;
    border-top: 7px solid #8d3de2;
    thead {
     background-color: #8d3de2;
     tr {
      th {
       text-align: center;
       padding: 15px 0;
       color: blanchedalmond;
       border-right: 2px solid magenta;
       text-transform: uppercase;
       font-family: "Overpass", sans-serif;
      }
     }
    }
   }
   tbody {
    tr {
     td {
      text-align: center;
      color: #1c2746;
      background-color: #ffffff;
      padding: 15px 0px;
      border-right: 2px solid magenta;
     }
    }
    .change-color {
     td {
      background-color: #ecf0fc;
      padding: 15px 0px;
      border-right: 2px solid magenta;
     }
    }
   }
   tfoot {
    tr {
     th {
      text-align: center;

      button {
       border: none;
       padding: 11px 37px;
       width: 100%;
       background-color: #8d3de2;
       border-radius: 2px;
       color: #ffffff;
       font-size: 16px;
      }

      button:hover {
       background-color: rgb(91, 51, 134);
      }
     }
    }
   }
  }
 }
}
</style>
